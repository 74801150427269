
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/nl'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "nl"


dayjs.updateLocale("nl")
dayjs.locale('nl')


export default dayjs
