<template>
  <Header/>
  <div class="block maxw--inner">
    <div style="margin-bottom: 4rem;">
      <h1 class="text--primary heading-1 text--center" >{{ error?.statusCode }}</h1>
      <h2 class="text--primary heading-2 text--center" >{{ error?.statusCode === 404 ? 'Pagina niet gevonden' : error?.message }}</h2>
    </div>
    <div class="centered">
      <button @click="handleError" class="btn btn--accent mb-2">Terug naar homepagina</button>
    </div>
  </div>
  
  <Footer/>
</template>


<script setup lang="ts">
const props = defineProps({
  error: Object
})

const handleError = () => clearError({ redirect: '/' })
</script>
