<template>
  <header :class="{ has_banner: event?.banner }">
    <div class="hero__banner text--white fixed" v-if="event?.banner">
      <b>{{ event.banner.smallBannerText }}</b>
      <nuxt-link :to="`/evenementen/${event.slug}`" class="text--white">{{ event.banner.buttonText }} ></nuxt-link>
    </div>
    <Navigation/>
  </header>
</template>

<script lang="ts" setup>
import type EventPage from '~/typings/strapi/pages/eventPage';

const { find } = useStrapi();

const { data: events } = await useAsyncData(
  'events',
  () => find<EventPage>('event-pages', {
    populate: ['banner'],
    sort: 'publishedAt:desc',
    // filters: {
    //   $and: [
    //     { showFrom: { $lte: new Date().toISOString() } },
    //     { showTill: { $gt: new Date().toISOString() } }
    //   ]
    // }
  }),
  {
    transform: flattenCollection,
    lazy: true
  }
);

const event = computed<EventPage|null>(() => events.value !== null && events.value.length > 0 ? events.value[0] : null);
</script>
