<template>
  <div class="corner-logo">
    <NuxtImg src="/images/banner_corner.png" alt="corner_banner" class="corner" />
    <nuxt-link href="/" class="logo">
      <NuxtImg src="/images/logo.png" alt="logo" />
    </nuxt-link>
  </div>
</template>

<style lang="scss">
.corner-logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 312px;
  height: 176px;
  overflow: hidden;

  .corner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -16px;
    z-index: 0;
  }

  @include breakpoint(null, $sm) {
    width: 102px;
    height: 64px;

    .corner {
      right: -4px;
    }
  }

  .logo {
    width: auto;
    height: 128px;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    @include breakpoint(null, $sm) {
      height: 48px;
      top: 2px;
      right: -2px;
    }
  }
}
</style>
