import type { Strapi4ResponseMany, Strapi4ResponseSingle } from "@nuxtjs/strapi/dist/runtime/types";
import type Image from "@/typings/strapi/image";
import type MediaObject from "~/typings/strapi/media";

export function flattenObject<T>(object: Strapi4ResponseSingle<T> | undefined | null) {
  return {
    ...object?.data?.attributes,
    id: object?.data?.id
  } as T;
}

export function flattenCollection<T>(collection: Strapi4ResponseMany<T> | undefined | null) {
  if (!collection?.data) {
    return [];
  }

  return collection.data.map<T>((item) => ({
    ...item.attributes,
    id: item.id
  })) as Array<T>;
}

export function getStrapiImageLocation(image: Strapi4ResponseSingle<Image|MediaObject> | undefined | null, prefixed: boolean = false, returnAsString: boolean = true) {
  if (!image || !image.data) {
    return returnAsString ? 'undefined' : undefined;
  }

  const imageFlattened = flattenObject(image);
  return getFlattenedStrapiImageLocation(imageFlattened, prefixed);
}

export function getFlattenedStrapiImageLocation(image: Image | MediaObject | undefined | null, prefixed: boolean = false, returnAsString: boolean = true) {
  if (!image) {
    return returnAsString ? 'undefined' : undefined;
  }

  let value = `${image.hash || (returnAsString ? 'undefined' : undefined)}${image.ext}`;

  if (prefixed) {
    const runtimeConfig = useRuntimeConfig();
    value = `${runtimeConfig.public.strapi.url}/uploads/${value}`;
  }

  return value;
}
