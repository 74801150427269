import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#ffffff",
    "barButtonBackground": "#00723f",
    "barButtonColor": "#ffffff",
    "barButtonHoverBackground": "#ec6608",
    "barButtonHoverColor": "#ffffff",
    "barTextColor": "#000000",
    "checkboxActiveBackground": "#ec6608",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#b3d5c5",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#00170d",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#00723f",
    "controlButtonHoverBackground": "#00723f",
    "controlButtonIconColor": "#fff",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#ec6608",
    "modalBackground": "#fff",
    "modalButtonBackground": "#00723f",
    "modalButtonColor": "#ffffff",
    "modalButtonHoverBackground": "#ec6608",
    "modalButtonHoverColor": "#ffffff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "id": "n",
        "name": {
          "en": "Necessary",
          "nl": "Noodzakelijk"
        },
        "description": {
          "nl": "Noodzakelijke cookies helpen onze website bruikbaar te maken, door basisfuncties als paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te maken. Zonder deze cookies kan onze website niet naar behoren werken."
        }
      }
    ],
    "optional": [
      {
        "id": "a",
        "name": {
          "en": "Analytics",
          "nl": "Statistieken"
        },
        "description": {
          "nl": "Statistische cookies helpen ons begrijpen hoe bezoekers onze website gebruiken, door anoniem gegevens te verzamelen en te rapporteren."
        }
      },
      {
        "id": "p",
        "name": {
          "en": "Preferences",
          "nl": "Voorkeuren"
        },
        "description": {
          "nl": "Voorkeurscookies zorgen ervoor dat onze website informatie kan onthouden die van invloed is op het gedrag en de vormgeving van de website, zoals de taal van uw voorkeur of de regio waar u woont."
        }
      },
      {
        "id": "m",
        "name": {
          "en": "Marketing",
          "nl": "Marketing"
        },
        "description": {
          "nl": "Marketingcookies worden gebruikt om bezoekers te volgen wanneer ze verschillende websites bezoeken. Het doel is advertenties weergeven die zijn toegesneden op en relevant zijn voor iedere bezoeker."
        }
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "vewb_ncc_c",
  "cookieNameCookiesEnabledIds": "vewb_ncc_e",
  "cookieOptions": {
    "path": "/",
    "sameSite": "strict",
    "secure": true
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "nl",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "nl": {
      "accept": "Aanvaarden",
      "acceptAll": "Accepteer alles",
      "bannerDescription": "We gebruiken onze eigen cookies en third-party cookies om onze site te tonen en om te leren hoe deze gebruikt wordt, met het oog om de services die we verlenen te verbeteren. Door verder te gaan op onze site gaan we ervanuit dat hiermee akkoord gegaan wordt.",
      "bannerTitle": "Cookies",
      "close": "Sluiten",
      "cookiesFunctional": "Functionele cookies",
      "cookiesNecessary": "Noodzakelijke cookies",
      "cookiesOptional": "Optionele cookies",
      "decline": "Accepteer noodzakelijk",
      "declineAll": "Verwijder alles",
      "here": "hier",
      "iframeBlocked": "Om dit te kunnen bekijken dienen functionele cookies geaccepteerd te worden",
      "manageCookies": "Beheer cookies",
      "save": "Opslaan",
      "settingsUnsaved": "Er zijn niet-opgeslagen instellingen"
    }
  }
} as ModuleOptions