import { default as contactfzwMGjpTgRMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/contact.vue?macro=true";
import { default as _91slug_93QlFEMTsW5NMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/ervaringsverhalen/[slug].vue?macro=true";
import { default as _91slug_93YTSp5SGlM8Meta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/evenementen/[slug].vue?macro=true";
import { default as indexCCFjsum126Meta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/index.vue?macro=true";
import { default as privacy_45beleidq8X1gjOrIkMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/privacy-beleid.vue?macro=true";
import { default as _91slug_93d9wpa8zTQqMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/vacatures/[slug].vue?macro=true";
export default [
  {
    name: contactfzwMGjpTgRMeta?.name ?? "contact",
    path: contactfzwMGjpTgRMeta?.path ?? "/contact",
    meta: contactfzwMGjpTgRMeta || {},
    alias: contactfzwMGjpTgRMeta?.alias || [],
    redirect: contactfzwMGjpTgRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QlFEMTsW5NMeta?.name ?? "ervaringsverhalen-slug",
    path: _91slug_93QlFEMTsW5NMeta?.path ?? "/ervaringsverhalen/:slug()",
    meta: _91slug_93QlFEMTsW5NMeta || {},
    alias: _91slug_93QlFEMTsW5NMeta?.alias || [],
    redirect: _91slug_93QlFEMTsW5NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/ervaringsverhalen/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YTSp5SGlM8Meta?.name ?? "evenementen-slug",
    path: _91slug_93YTSp5SGlM8Meta?.path ?? "/evenementen/:slug()",
    meta: _91slug_93YTSp5SGlM8Meta || {},
    alias: _91slug_93YTSp5SGlM8Meta?.alias || [],
    redirect: _91slug_93YTSp5SGlM8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/evenementen/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexCCFjsum126Meta?.name ?? "index",
    path: indexCCFjsum126Meta?.path ?? "/",
    meta: indexCCFjsum126Meta || {},
    alias: indexCCFjsum126Meta?.alias || [],
    redirect: indexCCFjsum126Meta?.redirect || undefined,
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45beleidq8X1gjOrIkMeta?.name ?? "privacy-beleid",
    path: privacy_45beleidq8X1gjOrIkMeta?.path ?? "/privacy-beleid",
    meta: privacy_45beleidq8X1gjOrIkMeta || {},
    alias: privacy_45beleidq8X1gjOrIkMeta?.alias || [],
    redirect: privacy_45beleidq8X1gjOrIkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/privacy-beleid.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d9wpa8zTQqMeta?.name ?? "vacatures-slug",
    path: _91slug_93d9wpa8zTQqMeta?.path ?? "/vacatures/:slug()",
    meta: _91slug_93d9wpa8zTQqMeta || {},
    alias: _91slug_93d9wpa8zTQqMeta?.alias || [],
    redirect: _91slug_93d9wpa8zTQqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/vacatures/[slug].vue").then(m => m.default || m)
  }
]