<template>
  <footer class="block">
    <div class="maxw--inner footer__inner">
      <div class="footer__column left">
        <NuxtImg src="/images/logo.png" alt="logo" class="footer__logo" />
      </div>
      <div class="footer__column center">
        <a :href="data?.websiteLink?.link" class="mb-1">{{ data?.websiteLink?.content }}</a>
        <div class="footer__socials">
          <a :href="data?.instagramLink" v-if="data?.instagramLink?.length || 0 > 0">
            <Icon name="mdi:instagram" class="footer__social"/>
          </a>
          <a :href="data?.facebookLink" v-if="data?.facebookLink?.length || 0 > 0">
            <Icon name="mdi:facebook" class="footer__social"/>
          </a>
          <a :href="data?.linkedinLink" v-if="data?.linkedinLink?.length || 0 > 0">
            <Icon name="mdi:linkedin" class="footer__social"/>
          </a>
        </div>
      </div>
      <div class="footer__column right">
        <small>Alle Rechten Voorbehouden © {{ $dayjs().format('YYYY') }} - Van Eerd B.V.</small>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type Footer from '@/typings/strapi/components/footer';

const { findOne } = useStrapi();

const { data } = useAsyncData(
  'footer',
  () => findOne<Footer>('footer', { populate: '*' }),
  {
    transform: flattenObject
  }
);
</script>
