<template>
  <nav class="nav-wrapper">
    <div class="nav--desktop">
      <ul class="nav__items">
        <li v-for="navItem in navItems" :key="navItem.id">
          <NavigationLink :item="navItem"/>
        </li>
      </ul>
      <NavigationCornerLogo/>
    </div>
    <div class="nav--mobile">
      <div class="navigation__header">
        <NavigationBurger v-model="mobileOpen" />
        <NavigationCornerLogo/>
      </div>
      <div class="navigation__content" :class="{ open: mobileOpen }">
        <NavigationLink v-for="item in navItems" :item="item" :key="item.id" is-mobile @click="closeMenu" />
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import type Menu from '@/typings/strapi/menu';
import type MenuItem from '@/typings/strapi/menuItem';

const { findOne } = useStrapi();

const { data } = await useAsyncData(
  'headerMenu',
  () => findOne<Menu>('menus', 1, { populate: '*', nested: true }),
  {
    transform: flattenObject
  }
)

const navItems = computed(() => flattenCollection<MenuItem>(data.value?.items));

const mobileOpen = ref<boolean>(false);

const closeMenu = () => mobileOpen.value = false;
</script>
