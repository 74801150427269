export default defineNuxtPlugin(nuxtApp => {
  const cookieControl = useCookieControl();

  function hasCookieEnabled(key: string|Array<string>) {
    let enabled = false;

    if (Array.isArray(key)) {
      enabled = key.some(k => cookieControl.cookiesEnabledIds.value?.includes(k));
    } else {
      enabled = cookieControl.cookiesEnabledIds.value?.includes(key) || false;
    }

    return enabled;
  }

  function getDataLayerValue(key: string|Array<string>) {
    return hasCookieEnabled(key) ? 'granted' : 'denied';
  }

  if (hasCookieEnabled(['p', 'm', 'a'])) {
    const gtm = useGtm();

    // If any of the optional cookies are enabled, enable GTM and push dataLayer event
    gtm?.enable(true);

    // Also update the consent in the dataLayer
    window.dataLayer?.push('consent', 'update', {
      'ad_storage': getDataLayerValue('m'),
      'ad_user_data': getDataLayerValue('m'),
      'ad_personalization': getDataLayerValue(['p', 'm']),
      'analytics_storage': getDataLayerValue(['p', 'm', 'a']),
    });
  }
});
