export const appHead = {"meta":[{"charset":"utf-16"},{"name":"viewport","content":"width=device-width,initial-scale=1"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.ico"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/OfficinaSansStd-Bold.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/OfficinaSansStd-BoldItalic.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/OfficinaSansStd-Book.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/OfficinaSansStd-BookItalic.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/Van-Eerd.woff2"}],"style":[{"children":"@font-face{font-family:\"Officina Sans Std\";font-weight:700;font-style:normal;font-display:swap;src:url('/fonts/OfficinaSansStd-Bold.woff2') format('woff2');}@font-face{font-family:\"Officina Sans Std\";font-weight:700;font-style:italic;font-display:swap;src:url('/fonts/OfficinaSansStd-BoldItalic.woff2') format('woff2');}@font-face{font-family:\"Officina Sans Std\";font-weight:400;font-style:normal;font-display:swap;src:url('/fonts/OfficinaSansStd-Book.woff2') format('woff2');}@font-face{font-family:\"Officina Sans Std\";font-weight:400;font-style:italic;font-display:swap;src:url('/fonts/OfficinaSansStd-BookItalic.woff2') format('woff2');}@font-face{font-family:\"Van Eerd\";font-weight:400;font-style:normal;font-display:swap;src:url('/fonts/Van-Eerd.woff2') format('woff2');}"}],"script":[],"noscript":[],"htmlAttrs":{"lang":"nl"},"titleTemplate":"%s | Van Eerd - Werken bij","charset":"utf-16"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'