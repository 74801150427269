<template>
  <Header/>
  <NuxtPage/>
  <Footer/>
  <CookieControl locale="nl">
    <template #bar>
      <h2 class="heading-6 mb-1 text--primary">Cookies</h2>
      <p>
        We gebruiken cookies om inhoud en advertenties te personaliseren, sociale media-functies aan te bieden en ons verkeer te analyseren. We delen ook informatie over uw gebruik van onze site met onze sociale media-, advertentie- en analysepartners die deze kunnen combineren met andere informatie die u aan hen heeft verstrekt of die zij hebben verzameld via uw gebruik van hun services.
      </p>
    </template>
  </CookieControl>
</template>

<script lang="ts" setup>
const {
  cookiesEnabledIds
} = useCookieControl();

watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (
      !previous?.includes('a') &&
      current?.includes('a')
    ) {
      // cookie with id `a` (analytics) got added
      // reload the page in order to initialize the analytics
      window.location.reload()
    }
  },
  { deep: true },
)
</script>
